var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":24,"lg":12,"xl":6}},[_c('WidgetCounter',{attrs:{"title":stat.title,"value":stat.value,"prefix":stat.prefix,"suffix":stat.suffix,"icon":stat.icon,"status":stat.status}})],1)}),1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":12,"lg":12,"xl":24}},[_c('a-card',{staticClass:"card card-body border-0"},[_c('div',{staticClass:"mb-4",staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","margin-bottom":"20px"}},[_c('a-input-search',{staticStyle:{"width":"300px"},attrs:{"placeholder":"Recherche ici"},on:{"change":_vm.onSearch},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('router-link',{attrs:{"to":{ name: 'Collecteur_classement' }}},[_c('a-button',[_vm._v(" Classement des collecteurs ")])],1),_c('router-link',{attrs:{"to":{ name: 'Collecteur_cotisation' }}},[_c('a-button',[_vm._v(" Classement par cotisation ")])],1),_c('a-button',{staticStyle:{"margin-left":"15px"},attrs:{"type":"primary"},on:{"click":_vm.showModal}},[_vm._v(" Créer un agent collecteur ")])],1),_c('a-modal',{attrs:{"width":_vm.width,"title":"Creer un agent collecteur","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{attrs:{"span":16,"md":16}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.collecteurSubmit}},[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Nom du collecteur","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'nom',
                        {
                          initialValue: _vm.nom,
                          rules: [
                            {
                              required: true,
                              message: 'Nom du collecteur est vide!',
                            } ],
                        } ]),expression:"[\n                        'nom',\n                        {\n                          initialValue: nom,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Nom du collecteur est vide!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"type":"text","placeholder":"Nom agent collecteur"},model:{value:(_vm.nom),callback:function ($$v) {_vm.nom=$$v},expression:"nom"}})],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Prénom du collecteur","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'prenom',
                        {
                          initialValue: _vm.prenom,
                          rules: [
                            {
                              required: true,
                              message: 'Prénom du collecteur est vide!',
                            } ],
                        } ]),expression:"[\n                        'prenom',\n                        {\n                          initialValue: prenom,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Prénom du collecteur est vide!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"type":"text","placeholder":"Prénom agent collecteur"},model:{value:(_vm.prenom),callback:function ($$v) {_vm.prenom=$$v},expression:"prenom"}})],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Numéro de téléphone","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'numero',
                        {
                          initialValue: _vm.numero,
                          rules: [
                            {
                              required: true,
                              message: 'Numero est vide!',
                            } ],
                        } ]),expression:"[\n                        'numero',\n                        {\n                          initialValue: numero,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Numero est vide!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"type":"number","placeholder":"Numéro de téléphone"},model:{value:(_vm.numero),callback:function ($$v) {_vm.numero=$$v},expression:"numero"}})],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Agence","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'agence',
                        {
                          initialValue: _vm.agence,
                          rules: [
                            {
                              required: true,
                              message: 'agence est vide!',
                            } ],
                        } ]),expression:"[\n                        'agence',\n                        {\n                          initialValue: agence,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'agence est vide!',\n                            },\n                          ],\n                        },\n                      ]"}]},_vm._l((_vm.agences),function(agence){return _c('a-select-option',{key:agence.id,attrs:{"value":agence.id}},[_vm._v(" "+_vm._s(agence.libelle)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Quartier","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'quartier',
                        {
                          initialValue: _vm.quartier,
                          rules: [
                            {
                              required: true,
                              message: 'quartier est vide!',
                            } ],
                        } ]),expression:"[\n                        'quartier',\n                        {\n                          initialValue: quartier,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'quartier est vide!',\n                            },\n                          ],\n                        },\n                      ]"}]},_vm._l((_vm.quartiers),function(quartier){return _c('a-select-option',{key:quartier.id,attrs:{"value":quartier.id}},[_vm._v(" "+_vm._s(quartier.libelle)+" ")])}),1)],1)],1)],1)],1)],1),_c('a-col',{staticClass:"mt-4",attrs:{"span":8,"md":8}},[_c('a-card',{staticClass:"card-billing-info",attrs:{"bordered":false}},[_c('div',{staticClass:"col-info"},[_c('a-descriptions',{attrs:{"title":"Information du collecteur","column":1}},[_c('a-descriptions-item',{attrs:{"label":"Nom"}},[_vm._v(" "+_vm._s(_vm.nom)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Prenom"}},[_vm._v(" "+_vm._s(_vm.prenom)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Numéro de téléphone"}},[_vm._v(" (+228) "+_vm._s(_vm.numero)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Mot de passe"}},[_vm._v(" "+_vm._s(_vm.password)+" ")])],1)],1)])],1)],1)],1),_c('a-table',{staticStyle:{"margin-top":"20px"},attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":true},scopedSlots:_vm._u([{key:"etat",fn:function(text, record){return [(record.etat == true)?_c('span',{staticClass:"text-success"},[_vm._v("Online")]):_vm._e(),(record.etat == false)?_c('span',{staticClass:"text-danger"},[_vm._v("Offline")]):_vm._e()]}},{key:"operation",fn:function(text, record){return [_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"display":"flex","justify-content":"between"}},[_c('router-link',{staticStyle:{"margin-right":"10px"},attrs:{"to":{
                    name: 'Collecteur_detail',
                    params: { id: record.key },
                  }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1),(record.isActive == true)?_c('a-popconfirm',{attrs:{"title":"Sûre de bloquer?"},on:{"confirm":function () { return _vm.block(record.key, record.isActive); }}},[_c('a-button',{staticClass:"mx-2",attrs:{"type":"danger","size":"small"}},[_vm._v("Bloquer")])],1):_vm._e(),(record.isActive == false)?_c('a-popconfirm',{attrs:{"title":"Sûre de débloquer?"},on:{"confirm":function () { return _vm.block(record.key, record.isActive); }}},[_c('a-button',{staticClass:"mx-2",attrs:{"type":"success","size":"small"}},[_vm._v("Debloquer")])],1):_vm._e()],1)])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }